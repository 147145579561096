<template>
    <div class="w3-theme-l1" style="">
        <div class="w3-container w3-margin-top" style="padding-bottom:10px">
            <div style="height: 40px;">
				<div style="float: left;margin-right: 5px;">
					<select v-model="fieldFind.status" class="w3-select w3-small w3-white w3-border w3-round" @change="loadData">
						<option value="I">Masuk</option>
						<option value="S">sakit</option>
						<option value="A">Izin</option>
						<option value="F">Alfa</option>
					</select>
				</div>
				<div style="float: left;margin-right: 5px;">
					<select v-model="fieldFind.kelas" class="w3-select w3-small w3-white w3-border w3-round" @change="loadData">
						<option value ="-">Semua Kelas</option>
						<option v-for="(dtl, index) in $store.getters['modSekolahKelas/getRecsParam'](['sekolah_kelas_code','sekolah_kelas_name'])" :value="dtl.sekolah_kelas_code" :key="index">{{dtl.sekolah_kelas_name}}</option>
					</select>
				</div>
				<div style="float: left;position: relative;margin-right: 5px;">
					<input @change="loadData" style="height: 37px;" ref="tanggal" required type="date" class="w3-input w3-small w3-border w3-round" id="tanggal" placeholder="Tanggal"  v-model="fieldFind.tanggal">
				</div>
				<div style="float: left;margin-right: 5px;position: relative;min-width: 200px;">
					<input v-on:keyup.enter="loadData" v-model="fieldFind.nama" class="w3-input w3-small w3-border w3-round" style="height: 37px;" placeholder="cari nama siswa"/>
					<span class="w3-text-black" style="position: absolute;right: 1px;top: 1px; height: 35px; width: 35px; cursor: pointer;" ><i style="margin: 9px;" class="fa fa-search"></i></span>

                    <button title="Download excel" @click="downloadExcel" class="w3-blue w3-border-0 w3-white w3-round" type="button" style="position: absolute;right: -40px;top: 1px; height: 35px; width: 35px; cursor: pointer;">
                        <i class="fa fa-file-excel-o w3-text-blue"></i>
                    </button>
				</div>
                <div class="w3-right" style="width: 80px;">
                    <input v-model="$store.getters['modSiswaAbsen/pages']['batas']" type="number" class="w3-input w3-small w3-border w3-round" @change="loadData">
                </div>
			</div>
            <div @mouseover="grid = true" @mouseleave="grid = false">
                <vue-scrolling-table v-bind:style="'height:'+  (screenHeight+40) +'px;'" :class="'w3-table w3-striped w3-white w3-small freezeFirstColumn'" :include-footer="true" :dead-area-color="'w3-theme'" :scroll-horizontal="true" id="grid">
                    <template slot="thead">
                        <tr class="w3-border">
                            <th style="min-width:50px;" class="w3-light-grey w3-border-0 w3-border-right">
                                No
                            </th>
                            <th v-for="fl in $store.getters['modSiswaAbsen/filterByShow']" :key="fl.key" :style="'min-width:' + fl.width+
                                (fl['sort']['status']?';cursor:pointer':'')"
                                class="w3-light-grey w3-border-0" >
                                <div v-on:click="setFilterTable(fl)">{{fl.name}}
                                    <span v-if="fl['sort']['status']">
                                        <i class="fa"
                                            :class="fl['sort']['asc']=='asc'?'fa-sort-asc':(fl['sort']['asc']=='desc'?'fa-sort-desc':'fa-arrows-v')"></i>
                                    </span>
                                </div>
                            </th>
                        </tr>
                    </template>
                    <template slot="tbody">
                        <tr :id="'grid-' + index" @mouseover="dtl['pilih']=true" @mouseleave="dtl['pilih']=false" v-for="(dtl, index) in getRecs" style="cursor: pointer;" :class="(dtl.pilih==true?'bayangan w3-theme-l4':(index == $store.getters['modSiswaAbsen/selectedRow'] ?'bayangan w3-theme-l4':'w3-opacity-min'))"  :key="index" v-on:dblclick="showEdit(dtl, index);" class="w3-border-bottom w3-hover-border-grey">
                            <td style="min-width:50px;" class="w3-light-grey w3-border-0 w3-border-right">
                                <i :class="index == $store.getters['modSiswaAbsen/selectedRow'] ?'fa fa-caret-right':''"></i>{{index+1}}
                            </td>
                            <td v-on:click="$store.dispatch('modSiswaAbsen/setSelectedRow',index)" :style="'min-width:'+findObjFind('width','sekolah_kelas_name')" class="w3-border-0 w3-border-left">
                                <span v-html="setTextFind(dtl.sekolah_kelas_name,'sekolah_kelas_name')"></span>
                            </td>
                            <td v-on:click="$store.dispatch('modSiswaAbsen/setSelectedRow',index)" :style="'min-width:'+findObjFind('width','siswa_nis')" class="w3-border-0 w3-border-left">
                                <span v-html="setTextFind(dtl.siswa_nis,'siswa_nis')"></span>
                            </td>
                            <td v-on:click="$store.dispatch('modSiswaAbsen/setSelectedRow',index)" :style="'min-width:'+findObjFind('width','siswa_name')" class="w3-border-0 w3-border-left">
                                <span v-html="setTextFind(dtl.siswa_name,'siswa_name')"></span>
                            </td>
                            <td v-on:click="$store.dispatch('modSiswaAbsen/setSelectedRow',index)" :style="'min-width:'+findObjFind('width','siswa_absen_date')" class="w3-border-0 w3-border-left">
                                {{fieldFind.tanggal | moment("DD-MM-yyyy")}}
                            </td>
                            <td v-on:click="$store.dispatch('modSiswaAbsen/setSelectedRow',index)" :style="'min-width:'+findObjFind('width','siswa_absen_time')" class="w3-border-0 w3-border-left">
                                <span v-html="setTextFind(dtl.siswa_absen_time,'siswa_absen_time')"></span>
                            </td>
                            <td v-on:click="$store.dispatch('modSiswaAbsen/setSelectedRow',index)" :style="'min-width:'+findObjFind('width','siswa_absen_lat')" class="w3-border-0 w3-border-left">
                                <span v-html="setTextFind(dtl.siswa_absen_lat,'siswa_absen_lat')"></span>
                            </td>
                            <td v-on:click="$store.dispatch('modSiswaAbsen/setSelectedRow',index)" :style="'min-width:'+findObjFind('width','siswa_absen_long')" class="w3-border-0 w3-border-left">
                                <span v-html="setTextFind(dtl.siswa_absen_long,'siswa_absen_long')"></span>
                            </td>

                            <td v-on:click="$store.dispatch('modSiswa/setSelectedRow',index)" :style="'min-width:' + findObjFind('width','user_entry')"  class="w3-border-0 w3-border-left">
                                <span v-html="setTextFind(dtl.user_entry,'user_entry')"></span>
                            </td>
                            <td v-on:click="$store.dispatch('modSiswa/setSelectedRow',index)" :style="'min-width:' + findObjFind('width','date_entry')"  class="w3-border-0 w3-border-left">
                                {{dtl.date_entry | moment("DD-MM-yyyy HH:mm:ss")}}
                            </td>
                            <td v-on:click="$store.dispatch('modSiswa/setSelectedRow',index)" :style="'min-width:' + findObjFind('width','user_edit')"  class="w3-border-0 w3-border-left">
                                <span v-html="setTextFind(dtl.user_edit,'user_edit')"></span>
                            </td>
                            <td v-on:click="$store.dispatch('modSiswa/setSelectedRow',index)" :style="'min-width:' + findObjFind('width','date_edit')"  class="w3-border-0 w3-border-left">
                                {{dtl.date_edit | moment("DD-MM-yyyy HH:mm:ss")}}
                            </td>

                            <td style="padding: 3px 0px 0px 10px;" :style="'min-width:' + findObjFind('width','siswa_absen_status')" class=" w3-hide-small w3-hide-medium w3-center w3-border-left w3-border-right" >
                                <div v-on:click="$store.dispatch('modSiswaAbsen/setSelectedRow',index)" style="position: relative;" :style="'min-width:' + findObjFind('width','siswa_absen_status')">
                                    <span v-html="setStatus(dtl.siswa_absen_status)"></span>
                                    <div v-show="dtl['pilih']==true && dtl.siswa_absen_status=='T'" style="position: absolute; top: -5px; left: -5px; text-align:center;" class="w3-opacity-off">
                                        <button style="padding: 5px 7px 5px 7px; margin-right:5px;" class="w3-btn w3-circle w3-tiny w3-theme-d3" v-on:click="showEdit(dtl, index)"><i class="fa fa-pencil w3-large"></i></button>
                                        <button style="padding: 5px 7px 5px 7px; margin-right:5px;" class="w3-btn w3-circle w3-tiny w3-red" v-on:click="showDelete(dtl, index)"><i class="fa fa-trash-o w3-large"></i></button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </template>
                    <template slot="tfoot">
                        <tr style="display: block;">
                            <td style="padding: 0px;" :style="'display: contents;'">
                                <Paging class="w3-center"
                                    :click-handler="loadPage"
                                    :prev-text="'Prev'"
                                    :next-text="'Next'"
                                    :container-class="'w3-bar'"
                                    :page-count="pages.ttlhalaman"
                                    :page-range="10"
                                    :margin-pages="2"
                                    :value="pages.halaman">
                                </Paging>
                            </td>
                        </tr>
                    </template>
                </vue-scrolling-table>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
export default {
    head: {
        title: {
            inner: "Kehadiran"
        }
    },
    name: "Kehadiran",
    components: {},
    data() {
        return {
            "screenHeight" : 0,
            "pages" : {halaman : 1, batas : 0, ttlhalaman : 0, ttlrec:0, autoadd :"F"},
            "filters" : {},
            "json_fields" : {},
            "fieldFind": {"status": "I", "kelas": "-", "tanggal": moment(String(new Date())).format("YYYY-MM-DD"), "nama": ""}
        };
    },
    methods : {
        findObjFind(key, val){
            // console.log(key, val);
            return this.findObj(this.filters.fields,"key",val)[key];
        },
        setTextFind(txt){
            return this.sethighlight(txt,this.fieldFind.nama);            
        },
        loadPage (pageNum) {
            this.pages.halaman = pageNum;
            this.$store.dispatch("modSiswaAbsen/setPages",this.pages);
            this.$store.dispatch("modSiswaAbsen/setFilters",this.filters);
            this.loadData();
        },
        setFilter (filter, operator) {
            this.filters.find = filter;
            this.filters.operator = operator;
            this.$store.dispatch("modSiswaAbsen/setFilters",this.filters);
            this.loadData();
        },
        loadData () {
            this.$store.dispatch("modSiswaAbsen/setSelectedRow",-1);
            this.filters.fieldselect = "";
            this.filters.order = "";
            this.filters.group = "";
            let find = [{
                "kondisi": "=",
                "field": "siswa_absen_status",
                "fieldValue": this.fieldFind.status
            }];
            this.filters.order= "sekolah_kelas_name";
            if(this.fieldFind.status == "F"){
                find = [
                    {
                        "kondisi" : "not in;=;!=",
                        "field" : "siswa.siswa_code;sekolah_kelas_status;siswa.sekolah_kelas_code",
                        "fieldValue" : `(select siswa_code from brantas.siswa_absen where siswa_absen_date='${this.fieldFind.tanggal}');T;c4ca4238a0b923820dcc509a6f75810A`

                    }
                ];
                // eslint-disable-next-line no-console
                console.log("Kondisi F ", find);
                this.filters.fieldselect = "siswa_nis, siswa.siswa_name, '' as siswa_absen_date_edit, '' as siswa_absen_time, sekolah_kelas_name";
                this.filters.order= "siswa.sekolah_kelas_code, siswa.siswa_name";
                this.filters.group= "siswa.sekolah_kelas_code, siswa.siswa_name, siswa.siswa_code, sekolah_kelas_name";
                // this.pages.batas = 1000;
            }
            if(this.fieldFind.status=="I"){
                find["kondisi"] = "in";
                find["field"] = "siswa_absen_status";
                find["fieldValue"] = "('I','O')";
            }
            
            if (this.fieldFind.kelas!="-"){
                find.push({
                    "kondisi": "=",
                    "field": "siswa.sekolah_kelas_code",
                    "fieldValue": this.fieldFind.kelas
                });
            }
            if (this.fieldFind.tanggal!=""){
                if(this.fieldFind.status != "F")
                find.push({
                    "kondisi": "=",
                    "field": "siswa_absen_date",
                    "fieldValue": this.fieldFind.tanggal
                });
            }
            if (this.fieldFind.nama!=""){
                find.push({
                    "kondisi": "like",
                    "field": "siswa_name",
                    "fieldValue": this.fieldFind.nama
                });
            }
            this.filters.find = find;
            this.filters.operator = "AND";
            this.$store.dispatch("modSiswaAbsen/loadData");
        },
        setStatus(dt) {
            if (dt === "I") {
                return "<div class='w3-green w3-center w3-round' style='width:50px;margin-top:5px;'>Masuk</div>";
            }else
            if (dt === "S") {
                return "<div class='w3-green w3-center w3-round' style='width:50px;margin-top:5px;'>Sakit</div>";
            }else
            if (dt === "A") {
                return "<div class='w3-green w3-center w3-round' style='width:50px;margin-top:5px;'>Izin</div>";
            }
            return "<div class='w3-red w3-center w3-round' style='width:50px;margin-top:5px;'>In Active<div>";
        },
        downloadExcel(){
            this.showLoading("Generate excel");
            let vhtml = "<table>";
            setTimeout(() => {
                vhtml+="<tr>";
                vhtml+="<th>No</th><th>Kelas</th><th>NIS</th><th>Nama</th><th>Tanggal</th><th>Jam</th>";
                vhtml+="</th>";
                for (let index = 0; index < this.$store.getters["modSiswaAbsen/recs"].length; index++) {
                    const element = this.$store.getters["modSiswaAbsen/recs"][index];
                    vhtml+="<tr>";
                    vhtml+="<td>";
                    vhtml+= index+1;
                    vhtml+="</td>";
                    vhtml+="<td>";
                    vhtml+= element["sekolah_kelas_name"];
                    vhtml+="</td>";
                    vhtml+="<td>";
                    vhtml+= element["siswa_nis"];
                    vhtml+="</td>";
                    vhtml+="<td>";
                    vhtml+= element["siswa_name"];
                    vhtml+="</td>";
                    vhtml+="<td>";
                    vhtml+= moment(element["siswa_absen_date"]).format("YYYY-MM-DD");
                    vhtml+="</td>";
                    vhtml+="<td>";
                    vhtml+= element["siswa_absen_time"];
                    vhtml+="</td>";
                    vhtml+="</tr>";
                }
                vhtml += "</table>";
                this.downloadFile("absensi-siswa.xls",vhtml,"data:text/xls");
                setTimeout(() => {
                    this.hideLoading();
                }, 1000);
            }, 500);
        },
        setFilterTable(dt){
            if(!this.setFilterHeader(dt, this.filters)) {
                this.loadData();
            }
        }
    },
    computed :{
        getRecs() {
            return this.$store.getters["modSiswaAbsen/recs"];
        }
    },
    filters: {
        toRp (value) {
            // eslint-disable-next-line new-cap
            return Intl.NumberFormat("de-DE").format(value);
        }
    },
    watch: {
        "$route": function(){
        }
    },
    created(){
        this.$store.getters["modSiswaAbsen/filters"].find = [];
        this.pages = this.$store.getters["modSiswaAbsen/pages"];
        this.filters = this.$store.state.modSiswaAbsen.filters;
        this.$store.getters["modSekolahKelas/pages"]["batas"] = 100;
        this.$store.dispatch("modSekolahKelas/loadData");
    },
    mounted(){
        const self = this;
        for (let index = 0; index < self.$store.getters["modSiswaAbsen/filterByShow"].length; index++) {
            const element = self.$store.getters["modSiswaAbsen/filterByShow"][index];
            if (element.sort.asc != "-"){
                if (element.sort.asc=="desc"){
                    element.sort.asc= "asc";
                }else{
                    element.sort.asc= "-";
                }
                self.setFilterHeader(element, self.$store.state.modSiswaAbsen.filters);
            }
        }
        self.recAkses = Object.assign({}, self.$store.state.modAksesmenu.recAkses[self.$route.name.toUpperCase()]);
        self.screenHeight = window.innerHeight -190;
        self.getGridColumnmAutoWidth("grid",self.filters.fields, false);
        self.loadData();
        self.eventGridStore(self, "grid", "modSiswaAbsen/selectedRow",function(nilai){
            self.$store.dispatch("modSiswaAbsen/setSelectedRow",nilai);
        });
    }
};
</script>